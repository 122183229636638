@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);


body {
  background-color: #f0f0f0;
  font-family: 'Roboto', sans-serif;
  color: #0a1122; }

.fade-in {
  -webkit-animation-name: fade-in;
          animation-name: fade-in;
  -webkit-animation-duration: 0.4s;
          animation-duration: 0.4s;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1; }

@-webkit-keyframes fade-in {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fade-in {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.product-img {
  width: 80px; }

.phone-codes {
  outline: none !important;
  box-shadow: none !important;
  max-width: 600px; }
  .phone-codes:active {
    outline: none !important;
    box-shadow: none !important; }
  .phone-codes:hover {
    outline: none !important;
    box-shadow: none !important; }

.form-control {
  background-color: white;
  outline-color: #3daaaf; }

.border-primary {
  border: 2px solid #3daaaf !important; }

.image-select-2 {
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  background-color: white !important;
  position: relative;
  cursor: pointer !important;
  width: 100px;
  height: 100px;
  border-radius: 10px; }
  .image-select-2 i {
    font-size: 50px;
    color: #4d4d4d; }
  .image-select-2 .custom-file {
    position: absolute;
    background-color: transparent !important;
    width: 100% !important;
    height: 100% !important;
    left: 0 !important;
    top: 0 !important;
    opacity: 0 !important;
    padding: 0 !important;
    cursor: pointer !important;
    margin: 0 !important; }
    .image-select-2 .custom-file .custom-file-input {
      position: absolute;
      background-color: transparent !important;
      width: 100% !important;
      height: 100% !important;
      left: 0 !important;
      top: 0 !important;
      cursor: pointer !important; }
    .image-select-2 .custom-file .custom-file-label {
      position: absolute;
      background-color: transparent !important;
      width: 100% !important;
      height: 100% !important;
      left: 0 !important;
      top: 0 !important;
      cursor: pointer !important; }

.image-select {
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  background-color: white !important;
  position: relative;
  cursor: pointer !important;
  width: 150px;
  height: 150px;
  border-radius: 10px; }
  .image-select i {
    font-size: 50px;
    color: #0a1122; }
  .image-select .custom-file {
    position: absolute;
    background-color: transparent !important;
    width: 100% !important;
    height: 100% !important;
    left: 0 !important;
    top: 0 !important;
    opacity: 0 !important;
    padding: 0 !important;
    cursor: pointer !important;
    margin: 0 !important; }
    .image-select .custom-file .custom-file-input {
      position: absolute;
      background-color: transparent !important;
      width: 100% !important;
      height: 100% !important;
      left: 0 !important;
      top: 0 !important;
      cursor: pointer !important; }
    .image-select .custom-file .custom-file-label {
      position: absolute;
      background-color: transparent !important;
      width: 100% !important;
      height: 100% !important;
      left: 0 !important;
      top: 0 !important;
      cursor: pointer !important; }

.custom-file {
  box-shadow: none !important;
  outline: none !important; }
  .custom-file:active {
    box-shadow: none !important;
    outline: none !important; }
  .custom-file:hover {
    box-shadow: none !important;
    outline: none !important; }
  .custom-file .custom-file-input {
    box-shadow: none !important;
    outline: none !important;
    border-radius: 0 !important; }
    .custom-file .custom-file-input:active {
      box-shadow: none !important;
      outline: none !important; }
    .custom-file .custom-file-input:hover {
      box-shadow: none !important;
      outline: none !important; }
  .custom-file .custom-file-label {
    box-shadow: none !important;
    outline: none !important;
    border-radius: 0 !important;
    border-color: #3daaaf !important; }
    .custom-file .custom-file-label:active {
      box-shadow: none !important;
      outline: none !important;
      border-color: #3daaaf !important; }
    .custom-file .custom-file-label:hover {
      box-shadow: none !important;
      outline: none !important;
      border-color: #3daaaf !important; }

.complete-loading-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 9999999; }

.complete-loading {
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  -webkit-animation-name: fade_in_out;
          animation-name: fade_in_out;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-duration: 1s;
          animation-duration: 1s; }

@-webkit-keyframes fade_in_out {
  0% {
    opacity: 0.5; }
  50% {
    opacity: 1; }
  100% {
    opacity: 0.5; } }

@keyframes fade_in_out {
  0% {
    opacity: 0.5; }
  50% {
    opacity: 1; }
  100% {
    opacity: 0.5; } }

.spinner-wrapper {
  position: fixed;
  bottom: 2em;
  right: 2em;
  height: 6em;
  width: 6em;
  -webkit-animation-name: show_spinner;
          animation-name: show_spinner;
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1; }

@-webkit-keyframes show_spinner {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes show_spinner {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.spinner-custom {
  border-radius: 50%;
  border-width: 6px;
  border-style: solid;
  border-color: white #282828 white #282828;
  -webkit-animation-name: spinner_action;
          animation-name: spinner_action;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  height: 6em;
  width: 6em; }

@-webkit-keyframes spinner_action {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  50% {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes spinner_action {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  50% {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.auth-panel {
  max-width: 500px;
  padding: 2em;
  color: #3daaaf !important;
  background-color: #081718 !important;
  border-radius: 10px; }

.color-1 {
  color: #5e5e5e !important; }

.btn {
  font-weight: bold !important;
  box-shadow: none !important;
  outline: none !important;
  border-radius: 5px; }
  .btn:active {
    box-shadow: none !important;
    outline: none !important; }
  .btn:hover {
    box-shadow: none !important;
    outline: none !important; }

.btn-danger {
  -webkit-transform: translate(0, -2px);
          transform: translate(0, -2px);
  border-bottom: 4px solid #b12b38 !important;
  -webkit-transition: 0.3s;
  transition: 0.3s; }
  .btn-danger:hover {
    border-bottom: 4px solid  transparent !important;
    -webkit-transform: translate(0, 0px) !important;
            transform: translate(0, 0px) !important; }
  .btn-danger:active {
    border-bottom: 4px solid  transparent !important;
    -webkit-transform: translate(0, 0px) !important;
            transform: translate(0, 0px) !important; }

.btn-warning {
  -webkit-transform: translate(0, -2px);
          transform: translate(0, -2px);
  border-bottom: 4px solid #dda705 !important;
  -webkit-transition: 0.3s;
  transition: 0.3s; }
  .btn-warning i {
    color: white; }
  .btn-warning:hover {
    border-bottom: 4px solid  transparent !important;
    -webkit-transform: translate(0, 0px) !important;
            transform: translate(0, 0px) !important; }
  .btn-warning:active {
    border-bottom: 4px solid  transparent !important;
    -webkit-transform: translate(0, 0px) !important;
            transform: translate(0, 0px) !important; }

.btn-success {
  -webkit-transform: translate(0, -2px);
          transform: translate(0, -2px);
  border-bottom: 4px solid #1c7732;
  -webkit-transition: 0.3s;
  transition: 0.3s; }
  .btn-success:hover {
    border-bottom: 4px solid  transparent !important;
    -webkit-transform: translate(0, 0px) !important;
            transform: translate(0, 0px) !important; }
  .btn-success:active {
    border-bottom: 4px solid  transparent !important;
    -webkit-transform: translate(0, 0px) !important;
            transform: translate(0, 0px) !important; }

.btn-primary {
  background-color: #3daaaf !important;
  border-color: #3daaaf !important;
  border: none !important;
  border-bottom: 4px solid #308589 !important;
  -webkit-transform: translate(0, -2px);
          transform: translate(0, -2px);
  -webkit-transition: 0.3s;
  transition: 0.3s; }
  .btn-primary:hover {
    background-color: #3daaaf !important;
    border-color: #3daaaf !important;
    border-bottom: 4px solid  transparent !important;
    -webkit-transform: translate(0, 0px) !important;
            transform: translate(0, 0px) !important; }
  .btn-primary:active {
    background-color: #3daaaf !important;
    border-color: #3daaaf !important;
    border-bottom: 4px solid  transparent !important;
    -webkit-transform: translate(0, 0px) !important;
            transform: translate(0, 0px) !important; }

.text-invalid {
  padding-top: 10px;
  color: #fa6155; }

.bg-dark {
  background-color: #081718 !important; }

.side-nav {
  position: fixed;
  left: 0;
  top: 0;
  background-color: #081718 !important;
  width: 300px;
  height: 100vh;
  z-index: 20;
  overflow: hidden;
  overflow-y: auto; }

.side-nav ul {
  list-style-type: none;
  margin: 0 !important;
  padding: 0 !important;
  background-color: #3daaaf; }
  .side-nav ul hr {
    background-color: #525764;
    margin: 0;
    padding: 0;
    padding-left: 20px;
    padding-right: 20px; }

.side-nav ul li {
  padding: 1.3em;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  cursor: pointer;
  background-color: #081718 !important; }
  .side-nav ul li.active {
    -webkit-transform: translate(5px, 0);
            transform: translate(5px, 0); }
    .side-nav ul li.active span:nth-child(1) i {
      color: #3daaaf !important; }
    .side-nav ul li.active span:nth-child(2) a {
      color: #3daaaf !important; }

.side-nav ul li:hover {
  color: #3daaaf !important; }
  .side-nav ul li:hover span:nth-child(1) i {
    color: #3daaaf !important; }
  .side-nav ul li:hover span:nth-child(2) a {
    color: #3daaaf !important; }

.side-nav ul li span:nth-child(1) i {
  color: white;
  font-size: 30px !important;
  -webkit-transition: 0.3s;
  transition: 0.3s; }

.side-nav ul li span:nth-child(2) {
  margin-left: 1em; }
  .side-nav ul li span:nth-child(2):hover {
    color: #fa6155 !important; }

.side-nav ul li span:nth-child(2) a {
  -webkit-transition: 0.3s;
  transition: 0.3s; }

.side-nav ul li span:nth-child(2) a {
  text-decoration: none !important;
  color: white !important;
  font-size: 20px; }

.side-nav-shrinked ul li {
  text-align: center; }

.side-nav-shrinked ul li span:nth-child(2) {
  display: none !important; }

.flex-center {
  display: flex;
  justify-content: center;
  flex-wrap: wrap; }

.flex-between {
  display: flex;
  justify-content: space-between; }

.flex-left {
  display: flex;
  justify-content: start; }

.smooth-show {
  -webkit-animation-name: smooth_show;
          animation-name: smooth_show;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1; }

.table {
  border-radius: 10px;
  overflow: hidden; }
  .table thead {
    background-color: white !important; }
    .table thead th {
      border-top: 0 !important; }
  .table tbody {
    background-color: white !important; }

.dash-item {
  padding: 25px;
  border-radius: 10px;
  min-width: 250px;
  cursor: pointer;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  background-color: white; }
  .dash-item .icon i {
    font-size: 60px;
    color: #081718 !important; }
  .dash-item .dash-item-text {
    color: #081718 !important; }
  .dash-item:hover {
    box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.1); }

.white-card {
  background-color: white;
  padding: 20px;
  border-radius: 10px; }

.vendor-item {
  background-color: white;
  border-radius: 10px;
  width: 100% !important;
  position: relative; }
  .vendor-item .vendor-body {
    padding: 15px; }
    .vendor-item .vendor-body .row {
      padding: 0;
      margin: 0; }
    .vendor-item .vendor-body .profile_img {
      width: 70px; }
  .vendor-item .vendor-footer {
    padding: 15px;
    border-top: 1px solid lightgrey; }
  .vendor-item .action-buttons {
    position: absolute;
    top: 5px;
    right: 5px; }
    .vendor-item .action-buttons .btn {
      padding: 5px; }
      .vendor-item .action-buttons .btn i {
        font-size: 20px;
        color: grey; }

.primary-text {
  color: #3daaaf !important; }

.text-danger-2 {
  color: #b91f03 !important; }

.user-client-item {
  background-color: white;
  border-radius: 10px;
  width: 100% !important;
  position: relative; }
  .user-client-item .primary-text {
    color: #3daaaf; }
  .user-client-item .text-danger-2 {
    color: #b91f03; }
  .user-client-item .order-header {
    padding: 15px;
    border-bottom: 1px solid lightgrey; }
  .user-client-item .order-body {
    padding: 15px; }
    .user-client-item .order-body .row {
      padding: 0;
      margin: 0; }
    .user-client-item .order-body .profile_img {
      width: 70px; }
  .user-client-item .order-footer {
    padding: 15px;
    border-top: 1px solid lightgrey; }
  .user-client-item .action-buttons {
    position: absolute;
    top: 5px;
    right: 5px; }
    .user-client-item .action-buttons .btn {
      padding: 5px; }
      .user-client-item .action-buttons .btn i {
        font-size: 20px;
        color: grey; }

.order-item {
  background-color: white;
  border-radius: 10px;
  width: 100% !important;
  position: relative; }
  .order-item .primary-text {
    color: #3daaaf; }
  .order-item .text-danger-2 {
    color: #b91f03; }
  .order-item .order-header {
    padding: 15px;
    border-bottom: 1px solid lightgrey; }
  .order-item .order-body {
    padding: 15px; }
    .order-item .order-body .row {
      padding: 0;
      margin: 0; }
    .order-item .order-body .profile_img {
      width: 70px; }
  .order-item .order-footer {
    padding: 15px;
    border-top: 1px solid lightgrey; }
  .order-item .action-buttons {
    position: absolute;
    top: 5px;
    right: 5px; }
    .order-item .action-buttons .btn {
      padding: 5px; }
      .order-item .action-buttons .btn i {
        font-size: 20px;
        color: grey; }

.client-item {
  background-color: white;
  border-radius: 10px;
  width: 100% !important;
  position: relative; }
  .client-item .client-body {
    padding: 15px; }
    .client-item .client-body .row {
      padding: 0;
      margin: 0; }
    .client-item .client-body .profile_img {
      width: 70px; }
  .client-item .client-footer {
    padding: 15px;
    border-top: 1px solid lightgrey; }
  .client-item .action-buttons {
    position: absolute;
    top: 5px;
    right: 5px; }
    .client-item .action-buttons .btn {
      padding: 5px; }
      .client-item .action-buttons .btn i {
        font-size: 20px;
        color: grey; }

@-webkit-keyframes smooth_show {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes smooth_show {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.logo img {
  width: 400px; }

@media screen and (max-width: 800px) {
  .side-nav {
    display: none !important; }
  .spinner-wrapper {
    position: fixed;
    bottom: 1em;
    right: 1em;
    height: 3em;
    width: 3em;
    -webkit-animation-name: show_spinner;
            animation-name: show_spinner;
    -webkit-animation-duration: 0.5s;
            animation-duration: 0.5s;
    -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1; }
  .spinner-custom {
    height: 3em;
    width: 3em; }
  .auth-panel {
    position: static !important;
    -webkit-transform: translate(0, 0) !important;
            transform: translate(0, 0) !important;
    max-width: 100vw;
    padding: 2em;
    color: white !important;
    background-color: #081718 !important;
    margin-top: 20px !important; }
  .logo {
    margin-bottom: 40px; }
    .logo img {
      width: 200px; } }

